import { create } from 'zustand';
import { applyEdgeChanges, applyNodeChanges, addEdge } from '@xyflow/react';
import useMessageStore from './messageStore';
import useDevStore from './devStore';
import useUserStore from './userStore';

// Create a Zustand store to manage global state
const useArgumentStore = create((set, get) => ({
  // Nodes and edges state
  nodes: [
    {
      id: '1',
      type: 'customNode',
      position: { x: 200, y: 100 },
      data: { 
        value: 'Socrates is a man',
        handles: [
          {
            id: 'top-a',
            type: 'independent',
            position: 'top'
          },
          {
            id: 'bottom-a',
            type: 'independent',
            position: 'bottom'
          }
        ],
      },
      draggable: true,
    },
    {
      id: '2',
      type: 'customNode',
      position: { x: 500, y: 100 },
      data: { 
        value: 'All men are mortal',
        handles: [
          {
            id: 'top-a',
            type: 'independent',
            position: 'top'
          },
          {
            id: 'bottom-a',
            type: 'independent',
            position: 'bottom'
          }
        ],
      },
      draggable: true,
    },
    {
      id: '3',
      type: 'customNode',
      position: { x: 350, y: 300 },
      data: { 
        value: 'Socrates is mortal',
        handles: [
          {
            id: 'top-a',
            type: 'dependent',
            position: 'top'
          },
          {
            id: 'bottom-a',
            type: 'independent',
            position: 'bottom'
          }
        ],
      },
      draggable: true,
    },
  ],
  edges: [
    {
      id: 'e1-3',
      source: '1',
      target: '3',
      sourceHandle: 'bottom-a',
      targetHandle: 'top-a',
    },
    {
      id: 'e2-3',
      source: '2',
      target: '3',
      sourceHandle: 'bottom-a',
      targetHandle: 'top-a',
    },
  ],

  // Deductive mode state
  isDeductive: true,

  // Loading state for evaluation
  isLoading: false,

  // Previous structure and message (for evaluation context)
  previousStructure: '',
  previousMessage: '',

  // Action to set nodes
  setNodes: (nodes) => set({ nodes }),

  // Action to set edges
  setEdges: (edges) => set({ edges }),

  // Handle node changes (e.g., position, selection)
  onNodesChange: (changes) =>
    set((state) => ({
      nodes: applyNodeChanges(changes, state.nodes),
    })),

  // Handle edge changes (e.g., deletion)
  onEdgesChange: (changes) =>
    set((state) => ({
      edges: applyEdgeChanges(changes, state.edges),
    })),

  // Handle new connections between nodes
  onConnect: (connection) =>
    set((state) => ({
      edges: addEdge(connection, state.edges),
    })),

  // Add a new node to the map
  addNewNode: () =>
    set((state) => ({
      nodes: [
        ...state.nodes,
        {
          id: `${state.nodes.length + 1}`,
          type: 'customNode',
          position: { x: Math.random() * 500, y: Math.random() * 300 },
          data: {
            value: ``,
            handles: [
              {
                id: 'top-a',
                type: 'independent',
                position: 'top'
              },
              {
                id: 'bottom-a',
                type: 'independent',
                position: 'bottom'
              }
            ],
          },
          draggable: true,
        },
      ],
    })),

  // Update node data (e.g., text content)
  updateNodeData: (nodeId, newData) =>
    set((state) => ({
      nodes: state.nodes.map((node) =>
        node.id === nodeId ? { ...node, data: { ...node.data, ...newData } } : node
      ),
    })),

  // Update node handles
  updateNodeHandles: (nodeId, newHandles) =>
    set((state) => {
      const updatedNodes = state.nodes.map((node) =>
        node.id === nodeId ? { ...node, data: { ...node.data, handles: newHandles } } : node
      );
  
      // Get all valid handle IDs from all nodes
      const validHandleIds = updatedNodes.flatMap(node => 
        node.data.handles.map(handle => handle.id)
      );
  
      // Only filter edges connected to the modified node
      const updatedEdges = state.edges.filter(edge => {
        if (edge.source === nodeId) {
          return validHandleIds.includes(edge.sourceHandle);
        }
        if (edge.target === nodeId) {
          return validHandleIds.includes(edge.targetHandle);
        }
        return true;
      });
  
      return {
        nodes: updatedNodes,
        edges: updatedEdges,
      };
    }),

  // Toggle deductive mode
  setIsDeductive: () => set((state) => ({ isDeductive: !state.isDeductive })),

  // Set loading state
  setIsLoading: (isLoading) => set({ isLoading }),

  // Handle evaluation logic
  handleEvaluate: async ({ fitView }) => {
    console.log('Starting evaluation...');
    const {
      nodes,
      edges,
      isDeductive,
      previousStructure,
      previousMessage,
    } = get();

    // Get settings from devStore
    const {
      prePrompt,
      firstPrompt,
      secondPrompt,
      model,
      temperature
    } = useDevStore.getState();

    // Get auth headers
    const { addAuthHeader } = useUserStore.getState();

    set({ isLoading: true });

    try {
      // Prepare the argument structure for evaluation
      const formattedArgumentStructure = {
        type: isDeductive ? 'deductive' : 'non-deductive',
        boxes: nodes.map((node) => {
          const nodeEdges = edges.filter(
            (edge) => edge.source === node.id || edge.target === node.id
          );

          const getConnectedBoxes = (handleId) => {
            return nodeEdges
              .filter(
                (edge) =>
                  edge.sourceHandle === handleId || edge.targetHandle === handleId
              )
              .map((edge) => (edge.source === node.id ? edge.target : edge.source));
          };

          return {
            id: node.id,
            text: node.data.value,
            handles: {
              top: (node.data.handles || [])
                .filter((h) => h.position === 'top')
                .map((h) => ({
                  connectionID: h.id,
                  connectionType: h.type || 'independent',
                  connectedBoxes: getConnectedBoxes(h.id),
                })),
              bottom: (node.data.handles || [])
                .filter((h) => h.position === 'bottom')
                .map((h) => ({
                  connectionID: h.id,
                  connectionType: h.type || 'independent',
                  connectedBoxes: getConnectedBoxes(h.id),
                })),
            },
          };
        }),
        connections: edges.map((edge) => ({
          from: edge.source,
          to: edge.target,
          sourceHandle: edge.sourceHandle,
          targetHandle: edge.targetHandle,
        })),
      };

      const payload = {
        argumentStructure: formattedArgumentStructure,
        previousStructure,
        previousMessage,
        argumentType: isDeductive ? 'deductive' : 'non-deductive',
        parserPrompt: prePrompt,
        evaluatorPrompt: firstPrompt,
        advisorPrompt: secondPrompt,
        model,
        temperature,
      };

      console.log('Sending evaluation payload:', payload);

      const response = await fetch('/api/map', {
        method: 'POST',
        headers: addAuthHeader({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        if (response.status === 401) {
          throw new Error('Authentication required');
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Received evaluation response:', data);

      if (data && data.tutorAdvice) {
        // Update previous structure and message
        set({
          previousStructure: data.parsedArgument,
          previousMessage: data.tutorAdvice,
        });
        
        // Add message to chat using messageStore
        useMessageStore.getState().addMessage(data.tutorAdvice, 'bot');
        
        // Adjust view if needed
        if (fitView) {
          setTimeout(fitView, 100);
        }
      } else {
        throw new Error('Unexpected response format from server');
      }
    } catch (error) {
      console.error('Evaluation error:', error);
      useMessageStore.getState().addMessage(
        'An error occurred while evaluating the argument: ' + error.message,
        'bot'
      );

      // If authentication error, trigger logout
      if (error.message === 'Authentication required') {
        useUserStore.getState().logout();
      }
    } finally {
      set({ isLoading: false });
    }
  },
}));

export default useArgumentStore;