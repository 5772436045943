import { create } from 'zustand';
import useUserStore from './userStore';

const useMessageStore = create((set) => ({
  messages: [
    {
      id: 1,
          text: `Hello, my name is Albert and I'm here to help you map out your arguments. Why don't we start by evaluating an argument? You can use the example map, or delete it and map your own argument. To evaluate, press the **Evaluate** button below.\n If you want, I can write an argument in prose (a normal paragraph) for you to map. In that case, write "give me a different argument to map" in chat and press **Send**'.`,
      type: 'bot',
      timestamp: new Date().toISOString()
    },

  ],
  addMessage: (message, type = 'user') => set((state) => ({
    messages: [...state.messages, {
      id: Date.now(),
      text: type === 'evaluation' ? message.trim() : message,
      type,
      timestamp: new Date().toISOString(),
      username: useUserStore.getState().user
    }]
  })),
  clearMessages: () => set({ messages: [] })
}));

export default useMessageStore;