import React, { useEffect, useState } from 'react';
import { Menu, Trash2, X, Save, Download } from 'lucide-react';
import useArgumentStore from './stores/argumentStore';
import useSavedStore from './stores/savedStore';
import useUserStore from './stores/userStore';
import useMessageStore from './stores/messageStore';
import './styles/ArgumentMenu.css';

const ArgumentMenu = () => {
  const { nodes, edges, isDeductive } = useArgumentStore();
  const { user } = useUserStore();
  const { messages } = useMessageStore();
  const { 
    savedArguments, 
    selectedArgumentId,
    initializeFromStorage,
    saveArgument,
    deleteArgument,
    setSelectedArgumentId,
    updateArgumentName,
    loadArgument
  } = useSavedStore();
  
  const [isOpen, setIsOpen] = useState(false);
  const [deleteConfirmId, setDeleteConfirmId] = useState(null);
  const [editingNameId, setEditingNameId] = useState(null);
  const [editingName, setEditingName] = useState('');

  useEffect(() => {
    initializeFromStorage();
  }, [initializeFromStorage]);

  const handleSave = () => {
    const currentArgument = {
      nodes,
      edges,
      isDeductive,
      messages
    };
    saveArgument(currentArgument);
  };

  const handleLoad = (argument) => {
    loadArgument(argument);
    setIsOpen(false);
  };

  const handleDelete = (id) => {
    if (deleteConfirmId === id) {
      deleteArgument(id);
      setDeleteConfirmId(null);
    } else {
      setDeleteConfirmId(id);
    }
  };

  const handleNameDoubleClick = (argument) => {
    setEditingNameId(argument.id);
    setEditingName(argument.name);
  };

  const handleNameSubmit = (e) => {
    e.preventDefault();
    if (editingNameId && editingName.trim()) {
      updateArgumentName(editingNameId, editingName.trim());
      setEditingNameId(null);
      setEditingName('');
    }
  };

  return (
    <div className="argument-menu">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="menu-button"
      >
        <Menu className="h-6 w-6" />
      </button>

      {isOpen && (
        <div className="argument-dropdown">
          <div className="archive-header">
            {user}'s Archive
          </div>

          <div className="current-argument">
            <div className="current-argument-text">Current Argument</div>
            <button
              onClick={handleSave}
              className="action-button save-button"
            >
              <Save className="h-4 w-4" />
              Save
            </button>
          </div>

          <div className="arguments-list">
            {savedArguments.map((argument) => (
              <div key={argument.id} className="argument-item">
                {editingNameId === argument.id ? (
                  <form onSubmit={handleNameSubmit} className="edit-name-form">
                    <input
                      type="text"
                      value={editingName}
                      onChange={(e) => setEditingName(e.target.value)}
                      onBlur={handleNameSubmit}
                      onKeyDown={(e) => {
                        if (e.key === 'Escape') {
                          setEditingNameId(null);
                          setEditingName('');
                        }
                      }}
                      autoFocus
                      className="edit-name-input"
                    />
                  </form>
                ) : (
                  <div 
                    className="argument-details"
                    onDoubleClick={() => handleNameDoubleClick(argument)}
                  >
                    <div className="argument-name">{argument.name}</div>
                    <div className="argument-timestamp">
                      {new Date(argument.timestamp).toLocaleDateString()}
                    </div>
                  </div>
                )}
                
                <div className="argument-actions">
                  <button
                    onClick={() => handleLoad(argument)}
                    className="action-button load-button"
                    title="Load argument"
                  >
                    <Download className="h-4 w-4" />
                  </button>
                  <button
                    onClick={() => handleDelete(argument.id)}
                    className={`action-button delete-button ${
                      deleteConfirmId === argument.id ? 'confirm-delete' : ''
                    }`}
                    title={deleteConfirmId === argument.id ? 'Click to confirm delete' : 'Delete argument'}
                  >
                    {deleteConfirmId === argument.id ? (
                      <X className="h-4 w-4" />
                    ) : (
                      <Trash2 className="h-4 w-4" />
                    )}
                  </button>
                </div>
              </div>
            ))}
            {savedArguments.length === 0 && (
              <div className="empty-state">
                No saved arguments
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ArgumentMenu;