import { create } from 'zustand';

const getTokenFromCookie = () => {
  const cookies = document.cookie.split(';');
  for (let cookie of cookies) {
    const [name, value] = cookie.trim().split('=');
    if (name === 'token') return value;
  }
  return null;
};

const useUserStore = create((set, get) => ({
  user: null,
  isAuthenticated: false,
  role: null,
  token: null,
  isLoading: true,

  // Helper to add auth header
  addAuthHeader: (headers = {}) => {
    const { token } = get();
    return token ? { ...headers, 'Authorization': `Bearer ${token}` } : headers;
  },

  // Helper to check if user is admin
  isAdmin: () => {
    return get().role === 'admin';
  },

  // Standard login
  login: async (username, password) => {
    try {
      const response = await fetch('/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        throw new Error('Invalid credentials');
      }

      const data = await response.json();
      document.cookie = `token=${data.token}; path=/; max-age=86400; samesite=strict; domain=.diagram.academy; secure`;
      
      set({
        user: username,
        isAuthenticated: true,
        role: data.role,
        token: data.token,
        isLoading: false
      });

      return true;
    } catch (error) {
      set({ isLoading: false });
      return false;
    }
  },

  // Google login
  loginWithGoogle: async (credential) => {
    try {
      const response = await fetch('/api/auth/google', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ credential }),
      });

      if (!response.ok) {
        throw new Error('Google login failed');
      }

      const data = await response.json();
      document.cookie = `token=${data.token}; path=/; max-age=86400; samesite=strict; domain=.diagram.academy; secure`;
      
      set({
        user: data.user.email,
        isAuthenticated: true,
        role: 'user',
        token: data.token,
        isLoading: false
      });

      return true;
    } catch (error) {
      set({ isLoading: false });
      return false;
    }
  },

  logout: () => {
    document.cookie = 'token=; path=/; domain=.diagram.academy; expires=Thu, 01 Jan 1970 00:00:00 GMT';
    set({
      user: null,
      isAuthenticated: false,
      role: null,
      token: null
    });
    window.location.reload();
  },

  // Verify authentication
  checkAuth: async () => {
    const token = getTokenFromCookie();
    if (!token) {
      set({ isLoading: false });
      return false;
    }

    try {
      const response = await fetch('/api/auth/verify', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Invalid token');
      }

      const data = await response.json();
      set({
        user: data.username || data.user?.email,
        isAuthenticated: true,
        role: data.role,
        token,
        isLoading: false
      });
      
      return true;
    } catch {
      document.cookie = 'token=; path=/; domain=.diagram.academy; expires=Thu, 01 Jan 1970 00:00:00 GMT';
      set({
        user: null,
        isAuthenticated: false,
        role: null,
        token: null,
        isLoading: false
      });
      return false;
    }
  }
}));

export default useUserStore;