import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useUserStore from '../stores/userStore';
import '../styles/Login.css';

export default function Login() {
  const [username, setUsername] = useState(() => localStorage.getItem('lastUsername') || '');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showAdminLogin, setShowAdminLogin] = useState(() => localStorage.getItem('showAdminLogin') === 'true');
  const [googleScriptLoaded, setGoogleScriptLoaded] = useState(false);
  const { login, loginWithGoogle } = useUserStore();

  // Save admin/student preference
  useEffect(() => {
    localStorage.setItem('showAdminLogin', showAdminLogin);
  }, [showAdminLogin]);

  // Save username as it's typed
  useEffect(() => {
    localStorage.setItem('lastUsername', username);
  }, [username]);

  // Load Google script once
  useEffect(() => {
    if (!googleScriptLoaded) {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        setGoogleScriptLoaded(true);
      };
      document.body.appendChild(script);

      return () => {
        try {
          document.body.removeChild(script);
        } catch (e) {
          // Script might already be removed
        }
      };
    }
  }, [googleScriptLoaded]);

  // Initialize Google button whenever needed
  useEffect(() => {
    if (!showAdminLogin && googleScriptLoaded && window.google) {
      try {
        window.google.accounts.id.initialize({
          client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
          callback: async (response) => {
            if (response.credential) {
              const success = await loginWithGoogle(response.credential);
              if (!success) {
                setError('Login failed - please use your University of Auckland email');
              }
            }
          },
          allowed_parent_origin: window.location.origin,
          hosted_domains: ['auckland.ac.nz', 'aucklanduni.ac.nz'],
          auto_select: false
        });

        window.google.accounts.id.renderButton(
          document.getElementById('googleButton'),
          { 
            theme: 'outline', 
            size: 'large', 
            width: '320px',
            text: 'signin_with'
          }
        );
      } catch (error) {
        console.error('Error initializing Google button:', error);
      }
    }
  }, [loginWithGoogle, showAdminLogin, googleScriptLoaded]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    const success = await login(username, password);
    if (!success) {
      setError('Invalid credentials');
    }
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <img src="/Albert-min.png" alt="Einstein Icon" className="einstein-image" />
        <h1 className="product">Say Hello to Albert</h1>
        <div className="branding">a Diagram Academy product</div>
        
        <div className="auth-section">
          {showAdminLogin ? (
            <>
              <div className="section-label">ADMIN LOGIN</div>
              <form onSubmit={handleSubmit}>
                <div className="input-wrapper">
                  <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Username"
                    className="login-input"
                    required
                  />
                </div>
                
                <div className="input-wrapper">
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    className="login-input"
                    required
                  />
                </div>

                <button type="submit" className="login-button">
                  Enter
                </button>
              </form>
            </>
          ) : (
            <>
              <div id="googleButton" className="google-button-container"></div>
              <div className="auth-info">
                Sign in with your University of Auckland email
              </div>
            </>
          )}

          {error && <div className="error-message">{error}</div>}
        </div>

        <div className="login-links">
          <button 
            onClick={() => {
              setShowAdminLogin(!showAdminLogin);
              setError(''); // Clear any errors when switching
            }}
            className="toggle-link"
          >
            {showAdminLogin ? 'STUDENT LOGIN' : 'ADMIN LOGIN'}
          </button>
        </div>

        <div className="legal-links">
          <Link to="/privacy" className="legal-link">Privacy Policy</Link>
          <span className="legal-separator">•</span>
          <Link to="/terms" className="legal-link">Terms of Service</Link>
        </div>
      </div>
    </div>
  );
}