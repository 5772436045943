import { create } from 'zustand';
import axios from 'axios';
import useUserStore from './userStore';

const useDevStore = create((set, get) => ({
  isOpen: false,
  prePrompt: "",
  firstPrompt: "",
  secondPrompt: "",
  model: "GPT-4o",
  temperature: 0.7,
  openSection: "",
  notification: "",
  
  toggleOpen: async () => {
    const { isAdmin, addAuthHeader } = useUserStore.getState();
    
    // Only allow admin access to dev panel
    if (!isAdmin()) {
      console.log('Dev panel access denied: Admin privileges required');
      return;
    }

    try {
      // Verify admin access with backend
      const response = await fetch('/api/dev-access', {
        headers: addAuthHeader()
      });

      if (!response.ok) {
        throw new Error('Admin access required');
      }

      set(state => ({ isOpen: !state.isOpen }));
    } catch (error) {
      console.error('Dev panel access error:', error);
    }
  },
  
  setOpenSection: (section) => set({ openSection: section }),
  
  setPrompt: (promptType, value) => {
    // Only allow admin to modify prompts
    if (!useUserStore.getState().isAdmin()) {
      console.error('Prompt modification denied: Admin privileges required');
      return;
    }

    switch (promptType) {
      case 'pre':
        set({ prePrompt: value });
        break;
      case 'first':
        set({ firstPrompt: value });
        break;
      case 'second':
        set({ secondPrompt: value });
        break;
      default:
        console.error('Unknown prompt type:', promptType);
    }
  },
  
  setModel: (model) => {
    if (!useUserStore.getState().isAdmin()) {
      console.error('Model modification denied: Admin privileges required');
      return;
    }
    set({ model });
  },
  
  setTemperature: (temperature) => {
    if (!useUserStore.getState().isAdmin()) {
      console.error('Temperature modification denied: Admin privileges required');
      return;
    }
    if (isNaN(temperature) || temperature < 0 || temperature > 10) return;
    set({ temperature });
  },
  
  retrievePrompt: async (promptType) => {
    const { addAuthHeader } = useUserStore.getState();
    
    try {
      const response = await axios.get(`/api/${promptType}Prompt`, {
        headers: addAuthHeader()
      });
      const newPrompt = response.data.prompt;
      get().setPrompt(promptType, newPrompt);
    } catch (error) {
      console.error(`Error retrieving ${promptType} prompt:`, error);
    }
  },

  showNotification: (message) => {
    set({ notification: message });
    setTimeout(() => set({ notification: "" }), 1500);
  },
  
  submitComment: async (username, comment) => {
    const { addAuthHeader } = useUserStore.getState();
    
    try {
      await axios.post('/api/comment', 
        { username, comment },
        { headers: addAuthHeader() }
      );
      get().showNotification("Comment submitted successfully!");
      return true;
    } catch (error) {
      console.error('Error submitting comment:', error);
      get().showNotification("Failed to submit comment.");
      return false;
    }
  }
}));

export default useDevStore;