import React, { useEffect, useCallback, useRef } from 'react';
import { Handle, Position, useUpdateNodeInternals } from '@xyflow/react';
import useStore from './stores/argumentStore';

// Utility function to get an alphabet letter based on index
const getAlphabetLetter = (index) => String.fromCharCode(97 + index);

// Custom Node Component
const CustomNode = ({ id, data, draggable }) => {
  const updateNodeHandles = useStore((state) => state.updateNodeHandles);
  const updateNodeData = useStore((state) => state.updateNodeData);
  const edges = useStore((state) => state.edges);
  const updateNodeInternals = useUpdateNodeInternals();

  // Ensure data has default values to prevent undefined errors
  const nodeData = data || { handles: [], value: '' };
  const { handles = [], value = '' } = nodeData;

  // Separate handles by position
  const topHandles = handles.filter((h) => h.position === Position.Top);
  const bottomHandles = handles.filter((h) => h.position === Position.Bottom);

  const isInitialMount = useRef(true);
  const prevHandles = useRef(handles);

  // Effect to update node internals when handles change
  useEffect(() => {
    if (JSON.stringify(prevHandles.current) !== JSON.stringify(handles)) {
      prevHandles.current = handles;
      updateNodeInternals(id);
    }
  }, [handles, id, updateNodeInternals]);

  // Update handle types based on connected edges
  const updateHandleTypes = useCallback(
    (handles) =>
      handles.map((handle) => ({
        ...handle,
        type:
          edges.filter(
            (e) =>
              (e.target === id && e.targetHandle === handle.id) ||
              (e.source === id && e.sourceHandle === handle.id)
          ).length > 1
            ? 'dependent'
            : 'independent',
      })),
    [edges, id]
  );

  useEffect(() => {
    const updatedHandles = updateHandleTypes(handles);
    if (JSON.stringify(updatedHandles) !== JSON.stringify(handles)) {
      updateNodeHandles(id, updatedHandles);
      updateNodeInternals(id);
    }
  }, [edges, handles, id, updateHandleTypes, updateNodeHandles, updateNodeInternals]);

  // Handle text change
  const onChange = useCallback(
    (evt) => {
      updateNodeData(id, { value: evt.target.value });
    },
    [id, updateNodeData]
  );

  // Add a new handle
  const addHandle = useCallback(
    (position) => {
      const prefix = position === Position.Top ? 'top-' : 'bottom-';
      const existingHandles = handles.filter((h) => h.position === position);
      const newHandle = {
        id: `${prefix}${getAlphabetLetter(existingHandles.length)}`,
        type: 'independent',
        position,
      };
      updateNodeHandles(id, [...handles, newHandle]);
      updateNodeInternals(id);
    },
    [handles, id, updateNodeHandles, updateNodeInternals]
  );

  // Remove a handle
  const removeHandle = useCallback(
    (position) => {
      const existingHandles = handles.filter((h) => h.position === position);
      if (existingHandles.length > 1) {
        const updatedHandles = handles.filter(
          (h) => h.id !== existingHandles[existingHandles.length - 1].id
        );
        updateNodeHandles(id, updatedHandles);
        updateNodeInternals(id);
      }
    },
    [handles, id, updateNodeHandles, updateNodeInternals]
  );

  // Render handles for a given position
  const renderHandles = (handles, position) => {
    return handles.map((handle, index) => (
      <div
        key={handle.id}
        style={{
          position: 'absolute',
          [position === Position.Top ? 'top' : 'bottom']: '-10px',
          left: `${((index + 1) * 100) / (handles.length + 1)}%`,
          transform: 'translate(-50%, 0)',
        }}
      >
        <Handle
          type={position === Position.Top ? 'target' : 'source'}
          position={position}
          id={handle.id}
          style={{
            position: 'relative',
            left: '0',
            transform: 'none',
            backgroundColor: handle.type === 'dependent' ? '#ff8000' : '#4ecdc4',
          }}
        />
        {index === handles.length - 1 && (
          <button
            className="handle-button add"
            style={{
              [position === Position.Top ? 'top' : 'bottom']: '-10px',
              left: '20px',
            }}
            onClick={() => addHandle(position)}
          >
            +
          </button>
        )}
        {handles.length > 1 && index === handles.length - 1 && (
          <button
            className="handle-button remove"
            style={{
              [position === Position.Top ? 'top' : 'bottom']: '-10px',
              left: '35px',
            }}
            onClick={() => removeHandle(position)}
          >
            –
          </button>
        )}
      </div>
    ));
  };

  return (
    <div className="custom-node">
      {renderHandles(topHandles, Position.Top)}
      <textarea
        value={value}
        onChange={onChange}
        className="nodrag"
        rows={5}
        placeholder="Enter your text here..."
      />
      {renderHandles(bottomHandles, Position.Bottom)}
    </div>
  );
};

const nodeTypes = {
  customNode: CustomNode,
};

export default nodeTypes;