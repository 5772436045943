import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import {
  ReactFlow,
  ReactFlowProvider,
  Background,
  Controls,
  useReactFlow,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import './styles/ArgumentMap.css';
import nodeTypes from './CustomNodes';
import useArgumentStore from './stores/argumentStore';
import DevSettings from './DevSettings';
import ArgumentMenu from './ArgumentMenu';

const ToggleSwitch = React.memo(({ isDeductive, onChange }) => {
  return (
    <div className="toggle-switch" onClick={onChange}>
      <div className={`switch-handle ${isDeductive ? 'deductive' : 'non-deductive'}`}>
        {isDeductive ? '✓' : '%'}
      </div>
    </div>
  );
});

const ArgumentMapContent = React.memo(() => {
  const nodes = useArgumentStore((state) => state.nodes);
  const edges = useArgumentStore((state) => state.edges);
  const isDeductive = useArgumentStore((state) => state.isDeductive);
  const isLoading = useArgumentStore((state) => state.isLoading);
  const addNewNode = useArgumentStore((state) => state.addNewNode);
  const handleEvaluate = useArgumentStore((state) => state.handleEvaluate);
  const onNodesChange = useArgumentStore((state) => state.onNodesChange);
  const onEdgesChange = useArgumentStore((state) => state.onEdgesChange);
  const onConnect = useArgumentStore((state) => state.onConnect);
  const setIsDeductive = useArgumentStore((state) => state.setIsDeductive);

  const reactFlowWrapper = useRef(null);
  const initialLoadComplete = useRef(false);
  const { fitView } = useReactFlow();

  const memoizedNodeTypes = useMemo(() => nodeTypes, []);

  useEffect(() => {
    if (nodes.length > 0 && !initialLoadComplete.current) {
      setTimeout(() => {
        fitView({ padding: 0.2 });
        initialLoadComplete.current = true;
      }, 100);
    }
  }, [nodes.length, fitView]);

  const onEvaluate = useCallback(() => {
    handleEvaluate({ fitView });
  }, [handleEvaluate, fitView]);

  return (
    <>
      <div className="argument-map-container" ref={reactFlowWrapper}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={memoizedNodeTypes}
          proOptions={{ hideAttribution: true }}
          maxZoom={2}
          minZoom={0.5}
          defaultViewport={{ x: 0, y: 0, zoom: 1 }}
        >
          <Controls />
          <Background variant="dots" gap={12} size={1} />
        </ReactFlow>
        <ArgumentMenu />
        <div className="toggle-switch-container">
          <ToggleSwitch isDeductive={isDeductive} onChange={setIsDeductive} />
        </div>
        <div className="evaluate-container">
          <button
            className={`evaluate-button ${isLoading ? 'loading' : ''}`}
            onClick={onEvaluate}
            disabled={isLoading}
          >
            {isLoading ? <span className="loading-ellipsis"></span> : 'Evaluate'}
          </button>
        </div>
        <button 
          className="add-box-button" 
          onClick={addNewNode}
          disabled={isLoading}
        />
      </div>
      <DevSettings />
    </>
  );
});

const ArgumentMap = React.memo(() => {
  return (
    <ReactFlowProvider>
      <ArgumentMapContent />
    </ReactFlowProvider>
  );
});

export default ArgumentMap;