import { Link } from 'react-router-dom';

export default function Terms() {
  return (
    <div className="legal-page">
      <div className="legal-content">
        <h1>Terms of Service</h1>
        <p className="last-updated">Last updated: {new Date().toLocaleDateString()}</p>

        <section>
          <h2>1. Acceptance of Terms</h2>
          <p>By accessing Albert, you agree to be bound by these terms of service and all applicable laws and regulations.</p>
        </section>

        <section>
          <h2>2. University of Auckland Access</h2>
          <p>This service is exclusively for University of Auckland students and staff. Access requires a valid University of Auckland email address.</p>
        </section>

        <section>
          <h2>3. User Responsibilities</h2>
          <ul>
            <li>Maintain the confidentiality of your account</li>
            <li>Use the service for educational purposes only</li>
            <li>Do not share access with non-university users</li>
            <li>Respect intellectual property rights</li>
          </ul>
        </section>

        <section>
          <h2>4. Limitations</h2>
          <p>The service is provided "as is" without warranties. We reserve the right to modify or terminate the service at any time.</p>
        </section>

        <div className="legal-nav">
          <Link to="/" className="back-link">Back to Login</Link>
        </div>
      </div>
    </div>
  );
}