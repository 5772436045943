import { Link } from 'react-router-dom';

export default function Privacy() {
  return (
    <div className="legal-page">
      <div className="legal-content">
        <h1>Privacy Policy</h1>
        <p className="last-updated">Last updated: {new Date().toLocaleDateString()}</p>

        <section>
          <h2>1. Data Collection</h2>
          <p>We collect the following information:</p>
          <ul>
            <li>Your University of Auckland email address</li>
            <li>Authentication data for login purposes</li>
            <li>Argument maps and evaluations you create</li>
            <li>Chat interactions with the system</li>
          </ul>
        </section>

        <section>
          <h2>2. Data Usage</h2>
          <p>Your data is used to:</p>
          <ul>
            <li>Provide access to the argument mapping system</li>
            <li>Save and retrieve your work</li>
            <li>Improve the system's responses and functionality</li>
          </ul>
        </section>

        <section>
          <h2>3. Data Protection</h2>
          <p>We protect your data by:</p>
          <ul>
            <li>Using secure authentication methods</li>
            <li>Encrypting data in transit and at rest</li>
            <li>Restricting access to authorized University of Auckland users</li>
          </ul>
        </section>

        <section>
          <h2>4. Contact</h2>
          <p>For privacy concerns, contact: <a href="mailto:support@nscribe.ai">support@nscribe.ai</a></p>
        </section>

        <div className="legal-nav">
          <Link to="/" className="back-link">Back to Login</Link>
        </div>
      </div>
    </div>
  );
}